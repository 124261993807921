import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";
import PageFooter from "../../common/components/footer";
import PageHeader from "../../common/components/header";

function VerificationSuccess() {
  return (
    <Box maxW="7xl" margin={8}>
      <PageHeader />

      <Box margin={8}>
        <Alert
          status="success"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          borderRadius={6}
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Thank you for joining us!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            Your E-mail was confirmed successfully.
          </AlertDescription>
        </Alert>
      </Box>

      <PageFooter />
    </Box>
  );
}

export default VerificationSuccess;
