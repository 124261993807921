import { AtSignIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HttpClient } from "../../../application/protocols/http-client";
import { makeAxiosHttpClient } from "../../../main/factories/axios-http-client-factory";
import PageFooter from "../../common/components/footer";
import PageHeader from "../../common/components/header";
import ResendEmailModal from "./resend-email-modal";

type EmailVerificationProps = {
  httpClient: HttpClient<{ confirmationCode: string }, any>;
};

export function EmailVerification({ httpClient }: EmailVerificationProps) {
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [confirmationCode, setConfirmationCode] = useState("");
  const [loading, setLoading] = useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    const code = searchParams.get("confirmationCode");
    if (code) {
      setConfirmationCode(code);
    }
  }, [searchParams]);

  async function onSubmit() {
    try {
      setLoading(true);
      await httpClient.post({
        url: "/user/verify/link",
        body: {
          confirmationCode,
        },
      });
      setLoading(false);
      navigate("/verify/link/success");
      toast({
        title: "Done!",
        description: "E-mail verified",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error: any) {
      if (error.response) {
        const errorObject = error.response?.data;
        if (errorObject.data.code === "USR_ERR17") {
          toast({
            title: "Oops!",
            description: errorObject.message,
            status: "error",
            duration: 9000,
            isClosable: true,
            onCloseComplete: () => {
              setLoading(false);
              navigate("/verify/link/success");
            },
          });
        } else {
          toast({
            title: "Oops!",
            description: errorObject.message,
            status: "error",
            duration: 9000,
            isClosable: true,
            onCloseComplete: () => {
              setLoading(false);
              onOpen();
            },
          });
        }
      } else {
        toast({
          title: "Oops!",
          description:
            "Something went wrong with your request. Please verify and try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
          onCloseComplete: () => {
            setLoading(false);
          },
        });
      }
    }
  }

  return (
    <Box maxW="7xl" margin={8}>
      <PageHeader />

      <Box margin={8}>
        <Heading as="h1" size="lg">
          E-mail Confirmation
        </Heading>
        <Text marginTop={2} fontSize={"xl"}>
          Please confirm your E-mail by clicking on the button below.
        </Text>
        <Button
          disabled={loading}
          isLoading={loading}
          onClick={onSubmit}
          colorScheme="red"
          marginTop={5}
          leftIcon={<AtSignIcon />}
        >
          Confirm your E-mail
        </Button>
      </Box>
      <ResendEmailModal
        httpClient={makeAxiosHttpClient()}
        isOpen={isOpen}
        onClose={() => onClose()}
      />

      <PageFooter />
    </Box>
  );
}
