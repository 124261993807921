import { Flex, Heading } from "@chakra-ui/react";
import { Logo } from "./logo";

function PageHeader() {
  return (
    <Flex
      width="100%"
      as="nav"
      role="contentinfo"
      mx="auto"
      maxW="7xl"
    >
      <Flex align="center" justify="space-between">
        <Logo />
        <Heading as="h1" size="lg" letterSpacing={"tighter"} ml={3}>
          Xlung App
        </Heading>
      </Flex>
    </Flex>
  );
}

export default PageHeader;
