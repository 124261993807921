import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Heading,
  HStack,
  Link,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { colors } from "../../styles/colors";
import { Logo } from "./logo";

function PageFooter() {
  return (
    <Stack
      direction={{ base: "column", md: "row", sm: "row" }}
      width="100%"
      as="footer"
      role="contentinfo"
      my={10}
      mx="auto"
      maxW="7xl"
      py="8"
      px="8"
      color={"white"}
      bg={colors.primary}
      borderRadius={10}
    >
      <VStack alignItems={"flex-start"}>
        <HStack>
          <Logo height={"35px"} fillColor="#FFFFFF" />
          <Heading as="h1" size="lg" letterSpacing={"tighter"} ml={3}>
            Xlung
          </Heading>
        </HStack>
        <Text fontSize="m" style={{ color: "#FFF" }}>
          &copy; {new Date().getFullYear()} Xlung.net
        </Text>
      </VStack>
      <Spacer />

      <Button colorScheme="red" leftIcon={<ExternalLinkIcon />} variant="solid">
        <Link href="https://xlung.net/" isExternal>
          Visit Website
        </Link>
      </Button>
    </Stack>
  );
}

export default PageFooter;
