import { CheckIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { HttpClient } from "../../../application/protocols/http-client";
import PageFooter from "../../common/components/footer";
import PageHeader from "../../common/components/header";
import {
  fieldHasError,
  passwordConfirmationValidation,
  passwordValidation,
} from "./validation/password-reset-validation";

type PasswordResetFormProps = {
  httpClient: HttpClient<
    { confirmationCode: string; newPassword: string },
    any
  >;
};

type PasswordResetFormData = {
  password: string;
  passwordConfirmation: string;
};

function PasswordResetForm({ httpClient }: PasswordResetFormProps) {
  const [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<PasswordResetFormData>();

  const toast = useToast();
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showPasswordConfirmationField, setShowPasswordConfirmationField] =
    useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const togglePasswordVisibility = () =>
    setShowPasswordField(!showPasswordField);
  const togglePasswordConfirmationVisibility = () =>
    setShowPasswordConfirmationField(!showPasswordConfirmationField);

  useEffect(() => {
    const code = searchParams.get("confirmationCode");
    if (code) {
      setConfirmationCode(code);
    }
  }, [searchParams]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);
      await httpClient.post({
        url: "/user/change-password/link",
        body: {
          confirmationCode,
          newPassword: data.password,
        },
      });
      setLoading(false);
      toast({
        title: "Done!",
        description: "Password changed",
        status: "success",
        duration: 9000,
        isClosable: true,
        onCloseComplete: () => setSucceeded(true),
      });
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        const errorObject = error.response?.data;
        toast({
          title: "Oops!",
          description: errorObject.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Oops!",
          description:
            "Something went wrong with your request. Please verify and try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  });

  return (
    <Box maxW="7xl" margin={8}>
      <PageHeader />

      {!succeeded ? (
        <VStack marginX={[2, 8, 10, 20]} marginY={{ base: "20px", md: "10px" }}>
          <Spacer />
          <Box maxW={"xl"} width={"100%"}>
            <Heading as="h1" size="lg">
              Password Reset
            </Heading>
            <Text>Choose your new password</Text>
          </Box>

          <Spacer />

          <Box as="form" maxW={"xl"} width={"100%"} onSubmit={onSubmit}>
            <Text fontWeight={"bold"}>New Password:</Text>
            <InputGroup size="md">
              <Input
                {...register("password", {
                  validate: passwordValidation({
                    minLength: 6,
                    equalToValue: () => getValues("passwordConfirmation"),
                  }),
                })}
                pr="4.5rem"
                type={showPasswordField ? "text" : "password"}
                placeholder="Enter your password"
                focusBorderColor="black"
                errorBorderColor="red.500"
                isInvalid={fieldHasError("password", errors)}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={togglePasswordVisibility}
                >
                  {showPasswordField ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.password?.type === "requiredValidation" && (
              <Text fontSize={"sm"} marginTop={1} textColor={"red.500"}>
                Required
              </Text>
            )}
            {errors.password?.type === "minLengthValidation" && (
              <Text fontSize={"sm"} marginTop={1} textColor={"red.500"}>
                Must be at least 6 characters long
              </Text>
            )}

            <Text fontWeight={"bold"} marginTop={5}>
              New password confirmation:
            </Text>
            <InputGroup size="md">
              <Input
                {...register("passwordConfirmation", {
                  validate: passwordConfirmationValidation({
                    minLength: 6,
                    equalToValue: () => getValues("password"),
                  }),
                })}
                pr="4.5rem"
                type={showPasswordConfirmationField ? "text" : "password"}
                placeholder="Enter your password"
                focusBorderColor="black"
                errorBorderColor="red.500"
                isInvalid={fieldHasError("passwordConfirmation", errors)}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={togglePasswordConfirmationVisibility}
                >
                  {showPasswordConfirmationField ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.passwordConfirmation?.type === "requiredValidation" && (
              <Text fontSize={"sm"} marginTop={1} textColor={"red.500"}>
                Required
              </Text>
            )}
            {errors.passwordConfirmation?.type === "minLengthValidation" && (
              <Text fontSize={"sm"} marginTop={1} textColor={"red.500"}>
                Must be at least 6 characters long
              </Text>
            )}
            {errors.password?.type === "equalTo" &&
              errors.passwordConfirmation?.type === "equalTo" && (
                <Text fontSize={"sm"} marginTop={1} textColor={"red.500"}>
                  Both passwords must match
                </Text>
              )}

            <Button
              type="submit"
              disabled={
                loading ||
                fieldHasError("password", errors) ||
                fieldHasError("passwordConfirmation", errors)
              }
              isLoading={loading}
              size={"lg"}
              marginTop={"25px"}
              leftIcon={<CheckIcon />}
              width={"100%"}
              variant={"solid"}
              colorScheme={"red"}
            >
              Save
            </Button>
          </Box>
        </VStack>
      ) : (
        <VStack marginX={[2, 8, 10, 20]} marginY={{ base: "20px", md: "10px" }}>
          <Alert
            status="success"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
            borderRadius={6}
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Password changed
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              Now you can go back to Xlung App using your new password.
            </AlertDescription>
          </Alert>
        </VStack>
      )}

      <PageFooter />
    </Box>
  );
}

export default PasswordResetForm;
