import axios, { AxiosInstance } from "axios";
import { ApiConfig } from "../config/api-config";
import {
  HttpClient,
  HttpGetParams,
  HttpPostParams,
  HttpResponse,
} from "../protocols/http-client";

export default class AxiosHttpClient implements HttpClient<any, any> {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: ApiConfig.baseUrl,
    });
  }

  get(getParams: HttpGetParams<any>): Promise<HttpResponse<any>> {
    return this.client.get(getParams.url, {
      params: getParams.params,
      headers: getParams.headers,
    });
  }

  post(postParams: HttpPostParams<any>): Promise<HttpResponse<any>> {
    return this.client.post(postParams.url, postParams.body, {
      headers: postParams.headers,
    });
  }
}
