import { RepeatIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  Spacer,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import PageFooter from "../../common/components/footer";
import PageHeader from "../../common/components/header";

function VerificationError() {
  return (
    <Box maxW="7xl" margin={8}>
      <PageHeader />

      <Box margin={8}>
        <Stack direction={{ base: "column", md: "row", sm: "row" }}>
          <Flex flex={2}>
            <Alert
              status="error"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
              borderRadius={6}
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                Oops!
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                Something went wrong with your E-mail confirmation.
              </AlertDescription>
            </Alert>
          </Flex>

          <Spacer flex={0.1} />

          <VStack flex={2} alignItems={"flex-start"}>
            <Text fontWeight={"bold"}>E-mail:</Text>
            <InputGroup>
              <Input placeholder="Your E-mail" />
            </InputGroup>
            <Spacer flex={0.1} />
            <Button
              width={"100%"}
              colorScheme="red"
              leftIcon={<RepeatIcon />}
              alignSelf={"flex-end"}
            >
              Resend E-mail
            </Button>
          </VStack>
        </Stack>
      </Box>

      <PageFooter />
    </Box>
  );
}

export default VerificationError;
