import { BrowserRouter, Route, Routes } from "react-router-dom";
import { makeAxiosHttpClient } from "../../main/factories/axios-http-client-factory";
import PasswordResetForm from "../pages/password-reset/password-reset-form";
import { EmailVerification } from "../pages/verify/email-verification";
import VerificationError from "../pages/verify/verification-error";
import VerificationSuccess from "../pages/verify/verification-success";

export const Router = () => {
  const axiosHttpClient = makeAxiosHttpClient();
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/verify"
          element={<EmailVerification httpClient={axiosHttpClient} />}
        />
        <Route path="/verify/link/success" element={<VerificationSuccess />} />
        <Route path="/verify/link/error" element={<VerificationError />} />
        <Route
          path="/reset-password"
          element={<PasswordResetForm httpClient={axiosHttpClient} />}
        />
      </Routes>
    </BrowserRouter>
  );
};
