import { FieldError } from "react-hook-form";
import { equalToFieldValidation } from "../../../../application/validation/equal-to-field-validation";
import { minLengthFieldValidation } from "../../../../application/validation/min-length-field-validation";
import { requiredFieldValidation } from "../../../../application/validation/required-field-validation";

export function passwordValidation(options: {
  minLength: number;
  equalToValue: () => string;
}) {
  return {
    requiredValidation: requiredFieldValidation,
    minLengthValidation: (value?: string) =>
      minLengthFieldValidation(value, options.minLength),
    equalTo: (value?: string) =>
      equalToFieldValidation(value, options.equalToValue()),
  };
}

export function passwordConfirmationValidation(options: {
  minLength: number;
  equalToValue: () => string;
}) {
  return {
    requiredValidation: requiredFieldValidation,
    minLengthValidation: (value?: string) =>
      minLengthFieldValidation(value, options.minLength),
    equalTo: (value?: string) =>
      equalToFieldValidation(value, options.equalToValue()),
  };
}

export function fieldHasError(
  fieldName: "password" | "passwordConfirmation",
  errors: {
    password?: FieldError | undefined;
    passwordConfirmation?: FieldError | undefined;
  }
) {
  if (fieldName === "password") {
    if (
      errors.password?.type === "requiredValidation" ||
      errors.password?.type === "minLengthValidation" ||
      (errors.password?.type === "equalTo" &&
        errors.passwordConfirmation?.type === "equalTo")
    ) {
      return true;
    }
    return false;
  }
  if (fieldName === "passwordConfirmation") {
    if (
      errors.passwordConfirmation?.type === "requiredValidation" ||
      errors.passwordConfirmation?.type === "minLengthValidation" ||
      (errors.password?.type === "equalTo" &&
        errors.passwordConfirmation?.type === "equalTo")
    ) {
      return true;
    }
    return false;
  }
}
