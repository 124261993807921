import { RepeatIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { HttpClient } from "../../../application/protocols/http-client";
import { emailValidation } from "../../../application/validation/email-validation";

type ResendEmailModalProps = {
  isOpen: boolean;
  onClose(): void;
  httpClient: HttpClient<{ email: string }, any>;
};

type ResendEmailFormData = {
  email: string;
};

export default function ResendEmailModal({
  isOpen,
  onClose,
  httpClient,
}: ResendEmailModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResendEmailFormData>();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    try {
      setLoading(true);
      await httpClient.post({
        url: "/user/resend/link",
        body: {
          email: data.email,
        },
      });
      setLoading(false);
      onClose();
      navigate("/verify/link/success");
      toast({
        title: "Done!",
        description: "New confirmation link sent to your E-mail.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        const errorObject = error.response?.data;
        toast({
          title: "Oops!",
          description: errorObject.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Oops!",
          description:
            "Something went wrong with your request. Please verify and try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent as="form" onSubmit={onSubmit}>
        <ModalHeader>Resend E-mail confirmation link</ModalHeader>

        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text>
            Please inform your E-mail so we can send you a new confirmation
            link.
          </Text>
          <FormControl marginTop={5}>
            <FormLabel>E-mail:</FormLabel>
            <Input
              placeholder="Your E-mail"
              {...register("email", {
                validate: { email: emailValidation },
              })}
              focusBorderColor="black"
              errorBorderColor="red.500"
              isInvalid={errors.email?.type === "email"}
            />
            {errors.email?.type === "email" && (
              <Text fontSize={"sm"} marginTop={1} textColor={"red.500"}>
                Invalid E-mail
              </Text>
            )}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            disabled={loading || errors.email?.type === "email"}
            isLoading={loading}
            colorScheme="red"
            mr={3}
            leftIcon={<RepeatIcon />}
          >
            Generate new link
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
